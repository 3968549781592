<template>
  <div>
    <AppBar :title="$t('cardsStock.title')"/>
    <v-card flat v-if="stock" class="rounded-xl pt-2 pb-4">
      <v-card-text class="mb-4">
        <h3 class="mb-4">{{ $t('cardsStock.numberOfCards') }}: {{ stock[0].itemsInStock }}</h3>
        <v-btn @click="addCards" color="primary" depressed rounded>{{ $t('cardsStock.add') }}</v-btn>
        <v-btn @click="subCards" class="ml-4" depressed rounded>{{ $t('cardsStock.subtract') }}</v-btn>
      </v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 150px" class="text-center">{{ $t('changeHistory.date') }}</th>
              <th style="width: 250px" class="text-center">{{ $t('changeHistory.user') }}</th>
              <th class="text-left">{{ $t('changeHistory.name') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(log, i) in stock[0].logs" :key="i">
              <td style="width: 150px" class="text-center">{{ log.date | dateFormat("DD.MM.YYYY HH:mm") }}</td>
              <td class="text-center">{{ log.actionPerformer }}</td>
              <td>{{ log.actionName }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Add ref="addCard" @refresh="getCardsStock"></Add>
      <Substract ref="subCard" @refresh="getCardsStock"></Substract>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
import Add from '../Stock/Dialogs/add.vue'
import Substract from './Dialogs/substract.vue'
export default {
  components: { Add, Substract },
  name: 'CardsStock',
  data () {
    return {
      stock: null
    }
  },
  async created () {
    await this.getCardsStock()
  },
  methods: {
    async getCardsStock () {
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')
      try {
        const { data } = await Service.get(`/StockLists?countryCode=${countryCode}`)
        this.stock = data
      } catch (error) {}
      this.loading = false
    },
    addCards () {
      this.$refs.addCard.show(this.stock[0].id)
    },
    subCards () {
      this.$refs.subCard.show(this.stock[0].id)
    }
  }
}
</script>
