<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400" content-class="rounded-xl">
      <v-card class="rounded-xl">
        <v-card-title class="text-h5">
          {{$t('cardsStock.specifyNumberOfCards')}}
        </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="addForm" v-model="valid">
            <v-text-field v-model.number="itemsInStock" dense class="py-2" type="number" :rules="priceRule"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="add" :disabled="loading" :loading="loading" depressed rounded>
            {{ $t('cardsStock.subtract') }}
          </v-btn>
          <v-btn @click="close" :disabled="loading" depressed rounded>
            {{ $t('buttons.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Service from '@/services'
export default {
  name: 'Substract',
  data () {
    return {
      dialog: false,
      valid: false,
      loading: false,
      id: null,
      itemsInStock: null,
      priceRule: [
        v => !!v || this.$t('validation.fieldRequired'),
        v => v > 0 || this.$t('validation.incorrectFormat')
      ]
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.id = id
        this.dialog = true
      }
    },
    async add () {
      this.$refs.addForm.validate()

      if (!this.valid) return

      this.loading = true
      try {
        await Service.put(`/StockLists/${this.id}/Substract`, { itemsInStock: this.itemsInStock })

        this.$refs.addForm.reset()

        this.$emit('refresh')

        this.dialog = false
      } catch (error) {}
      this.loading = false
    },
    close () {
      this.$refs.addForm.reset()
      this.dialog = false
    }
  }
}
</script>
